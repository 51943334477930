@import "../../../styles/variables.module";
@import "../../../styles/fonts.module";

.button {
  height: 4.4rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem;
  :global(button) {
    margin: 1rem;
    :global(span) {
      font: 300 1.6rem/2.4rem $primary-font;
    }
  }
  :global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover){
    &:hover{
      border-color: $primary-button-color;
      background-color: $button-hover;
  }
  }
}

.card {
  border-radius: 1.8rem;

  :global(.ant-modal-content) {
    box-shadow: $card-box-shadow;
    border: 0.1rem solid $card-border;
    border-radius: 1.8rem;
    opacity: 1;
    padding: 1.4rem;
  }
}
.feedBackCard{
  border-radius: 1.8rem;
  :global(.ant-modal-content) {
    box-shadow: $card-box-shadow;
    border: 0.1rem solid $card-border;
    border-radius: 1.8rem;
    opacity: 1;
    padding: 1.4rem;
    width: 40rem;
  }
  .feedbackContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    .question{
      margin-top: 3rem;
      font: 600 2.4rem/3rem $secondary-font;
    }
  }
  :global(.ant-modal-footer){
    display: flex;
    align-items: center;
    justify-content: center;
    .feedbackButtonContainer{
      margin-bottom: 2rem;
      button{
        width: 10rem;
        height: 3rem;
      }
    }
  }
}

.lottiContainer {
  background: $success-card-bg 0% 0% no-repeat padding-box;
  box-shadow: $card-box-shadow;
  border-radius: 1.8rem;
  padding: 1rem 0rem;
  :global(h1) {
    font: 600 2.4rem/3rem $secondary-font;
  }
  :global(p) {
    font: 1.3rem/3rem $secondary-font;
  }
}

.lottiContainerText {
  text-align: center;
  h1{
    margin: 0;
  }
  p{
    margin-top: 0;
  }
}

.darkThemeModal{
  :global(.ant-modal-content) {
    box-shadow: $dark-card-border;
    border: 0.1rem solid $dark-card-border;
    border-radius: 1.8rem;
    opacity: 1;
    padding: 1.4rem;
    background-color: $dark-card-bg;
    .question{
      margin-top: 3rem;
      font: 600 2.4rem/3rem $secondary-font;
      color: $white;
    }
      span{
        i{
          color: $white;
        }
      }
  }
  .button {
    border-color: $previous-button-bg;
  }
}
.invertThemeModal{
  :global(.ant-modal-content) {
    border-color: $white;
    background-color: $black;
    .button{
      background-color: $white;
      :global(span) {
        color: $black;
      }
    }
    :global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover){
      &:hover{
        border-color: $invert-color;
        background-color: $invert-color;
    }
    }
    .question{
      margin-top: 3rem;
      font: 600 2.4rem/3rem $secondary-font;
      color: $white;
    }
      span{
        i{
          color: $white;
          &:hover{
            background-color: $invert-color;
            padding: 0.5rem;
          }
        }
      }
  }
}