

@import "_variables.module.scss";
@import "_helpers.module.scss";
@import "_@antOverrides.module.scss";
@import "_fonts.module.scss";
@import "_icons.module.scss";

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* General component styles */
body {
  background-color: $white;
  color: $primary-black;
  margin: 0rem;
  padding: 0rem;
  background: $body-background-color 0% 0% no-repeat padding-box;
  height: 100%;

  div[id="root"]{
    height: 100%;
    .network-status-wrapper{
      height: 100%;
    }
  }
  .ant-popover .ant-popover-inner{
    background-color: $body-background-color;;
  }
}
:root {
  --font-size: 68.5%;
}

html{
  font-size: var(--font-size);
}
h1{
  font-size: 2.4rem;
  line-height: 5.4rem;
}

h2{
  font-size: 2.4rem;
  line-height: 4.4rem;
}

h3{
  font-size: 2rem;
  line-height: 4.4rem;
}

h4{
  font-size: 2rem;
  line-height: 3.8rem;
}

h5{
  font-size: 1.2rem;
  line-height: 3.2rem;
}

h6{
  font-size: 1.4rem;
  line-height: 2.8rem;
}

body,
h1,
h2,
h3{
 font-family: $primary-font;
}

h4,
h5,
h6,
p{
  font-family: $secondary-font;
}
p{
  font-family: $secondary-font;
}
button,
input {
  font-family: $primary-font;
  font-weight: bold;
  height: 4rem;
}

ul{
  padding: 0rem;
  margin: 0rem;
}
.activeElement{
  background-color: $selection-yellow-color !important; // for dropdown options background color is not changing so i used !important
  color: $primary-button-color !important;
  span{
    color: $primary-button-color;
  }
  div{
    padding-left: 0.5rem;
    border: 0.1rem solid $selection-yellow-color ;
  }
}