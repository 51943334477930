@import "/src/styles/variables.module";
@import "/src/styles/fonts.module";


.pdfContainer {
  background-color: $body-background-color;
  width: 100%;
}
.wrapper{
  height: auto;
  width: 100%;
  padding: 5rem 0;
  display: flex;
  justify-content: center;
}
.darkTheme{
  background-color: $black;
  width: 100%;
}
.logo {
  height: auto;
  max-width: 16.8rem;
  object-fit: contain;
  left: 10rem;
  position: sticky;
  width: 100%;
}
@media (max-width:768px) {
  .wrapper{
    padding: 5rem 2rem;
  }
}
