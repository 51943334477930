@import "/src/styles/variables.module";
@import "/src/styles/fonts.module";

.wrapper {
  margin: 0 10rem 2rem;
  overflow-y: scroll;
  :global(span){
    font: 400 1.4rem/2.4rem $primary-font;
  }
  :global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover){
    &:hover{
      border-color: $primary-button-color;
    }
  }
  :global(.ant-card){
    padding: 1rem 5rem;
  }
}
.instructions {
  padding-left: 1.75rem;
  :global(p){
    font:  1.6rem/3.2rem $secondary-font;
    color: $instructions-text-color;
  }
}
.logo {
  max-width: 20.6rem;
  height: auto;
  object-fit: contain;
  width: 100%;
  margin-left: -1.2rem;
}

.instructionHeading{
  font: 500 2.4rem/3.4rem $primary-font;
}

@media (min-width:1920px) {
  .wrapper{
  margin: 20rem;
  }
  .logo {
    max-width: 20rem;
    margin-left: -1.1rem;
}
}

@media screen and (max-width:768px) {
  .logo {
      max-width: 16rem;
      margin-left: -0.7rem;
  }
  .wrapper{
    :global(button){
      width: 18rem;
      height: 4rem;
      :global(span){
        font: 300 1rem/1rem $primary-font;
      }
    }
  }
}
