@import "../../../styles/_variables.module.scss";
@import "../../../styles/_fonts.module.scss";

.sharedFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 5rem 1.6rem;
  border: 0.1rem solid $footer-background;
  background-color: $footer-background;
  position: fixed;
  width: 100%;
  bottom: 0rem;
  height: 15%;
}

.previousButton {
  background: $previous-button-bg 0% 0% no-repeat padding-box;
  border: 0.1rem solid $secondary-button-color;
  border-radius: 0.8rem;
  opacity: 1;
  width: 12rem;

  span {
    text-align: left;
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: $primary-font;
    letter-spacing: 0rem;
    color: $white;
    opacity: 1;
  }
}

.linkButton {
  background: transparent;
  border: none;
  opacity: 1;
  width: 12rem;
  cursor: pointer;

  span {
    text-align: left;
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: $primary-font;
    letter-spacing: 0rem;
    color: $white;
    opacity: 1;
  }
  &:global(.ant-btn-default:not(:disabled)) {
    &:hover {
      background-color: transparent;
      border: none;
    }
  }
}

.nextButton {
  background-color: $primary-button-color;
  border-radius: 0.8rem;
  opacity: 1;
  width: 12rem;
  border: 0.1rem solid $primary-button-color;

  span {
    text-align: center;
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: $primary-font;
    letter-spacing: 0rem;
    color: $white;
    opacity: 1;
  }
}

.buttonContainer {
  padding-right: 10rem;
  display: flex;
  gap: 2rem;
  :global(.ant-button) {
    font: 400 1.4rem/2.4rem $primary-font;
  }
}

.progressBarContainer {
  width: 25%;
  :global(.ant-progress-inner) {
    background-color: $white;
    :global(.ant-progress-bg) {
      background-color: $light-green;
    }
  }
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: $light-green;
}


.progressText {
  font: 400 1.6rem/3.4rem $font-lato;
  text-align: left;
  margin-top: 0.8rem;
  color: $light-green;
}
.previousButtonContainer {
  :global(.ant-btn-default:not(:disabled)) {
    &:hover {
      border-color: $secondary-button-color;
    }
  }
}
.nextButtonContainer {
  :global(.ant-btn-default:not(:disabled)) {
    &:hover {
      background-color: $button-hover;
      border-color: $primary-button-color;
    }
  }
}
.darkTheme{
  background-color: $dark-card-bg;
  border-color: $dark-card-border;
}
.invertTheme{
  background-color: $white;
  border: 0.1rem solid $black;
  width: 100%;
  position: fixed;
  bottom: 0rem;
  .progressBarContainer{
    .progressText {
      color: $black;
    }
    :global(.ant-progress-inner) {
      background-color: $black;
      :global(.ant-progress-bg) {
        background-color: $invert-red-color;
      }
    }
  }
  .buttonContainer{
    gap: 2rem;
  }
  .previousButtonContainer {
    :global(.ant-btn-default:not(:disabled)) {
      background-color: $black;
      &:hover {
        background-color: $invert-red-color;
        border-color: $invert-red-color;
      }
    }
  }
  .nextButtonContainer {
    :global(.ant-btn-default:not(:disabled)) {
      background-color: $black;
      &:hover {
        background-color: $invert-red-color;
        border-color: $invert-red-color;
      }
    }
  }
}
