@font-face {
    font-family: 'icomoon--NNAS';
    src:  url('./../assets/icons/icomoon--NNAS-icon.eot?sz187f');
    src:  url('./../assets/icons/icomoon--NNAS-icon.eot?sz187f#iefix') format('embedded-opentype'),
      url('./../assets/icons/icomoon--NNAS-icon.ttf?sz187f') format('truetype'),
      url('./../assets/icons/icomoon--NNAS-icon.woff?sz187f') format('woff'),
      url('./../assets/icons/icomoon--NNAS-icon.svg?sz187f#icomoon--NNAS-icon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon--NNAS' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-folder-upload:before {
    content: "\e91a";
  }
  .icon-Union-26:before {
    content: "\e923";
  }
  
  .icon-Group-787301 .path1:before {
    content: "\e921";
    color: rgb(88, 186, 185);
    opacity: 0.354;
  }
  .icon-Group-787301 .path2:before {
    content: "\e922";
    margin-left: -1em;
    color: rgb(88, 186, 185);
  }
  .icon-Group-78732 .path1:before {
    content: "\e91b";
    color: rgba(255, 48, 43, 0.33);
    opacity: 0.621;
  }
  .icon-Group-78732 .path2:before {
    content: "\e91c";
    margin-left: -1em;
    color: rgb(241, 91, 92);
  }
  .icon-Group-78731 .path1:before {
    content: "\e91d";
    color: rgb(255, 245, 234);
  }
  .icon-Group-78731 .path2:before {
    content: "\e91e";
    margin-left: -1em;
    color: rgb(237, 157, 27);
  }
  .icon-Group-78731 .path3:before {
    content: "\e91f";
    margin-left: -1em;
    color: rgb(237, 157, 27);
  }
  .icon-Group-78731 .path4:before {
    content: "\e920";
    margin-left: -1em;
    color: rgb(237, 157, 27);
  }
  .icon-sign-out-alt:before {
    content: "\e917";
  }
  .icon-user-2:before {
    content: "\e918";
  }
  .icon-account:before {
    content: "\e900";
  }
  .icon-angle-left:before {
    content: "\e901";
  }
  .icon-Compound-Path:before {
    content: "\e902";
  }
  .icon-downloads:before {
    content: "\e903";
  }
  .icon-filter:before {
    content: "\e904";
  }
  .icon-Group-77978:before {
    content: "\e905";
  }
  .icon-Group-78212:before {
    content: "\e906";
  }
  .icon-Group-78213:before {
    content: "\e907";
  }
  .icon-Group-78214:before {
    content: "\e908";
  }
  .icon-Group-78217:before {
    content: "\e909";
  }
  .icon-Group-78218:before {
    content: "\e90a";
  }
  .icon-Group-78220:before {
    content: "\e90b";
  }
  .icon-Group-78727:before {
    content: "\e919";
  }
  .icon-Group-78728:before {
    content: "\e90c";
  }
  .icon-Group-78729:before {
    content: "\e90d";
  }
  .icon-Icon-awesome-eye:before {
    content: "\e90e";
  }
  .icon-Layer-2:before {
    content: "\e90f";
  }
  .icon-Path-55379:before {
    content: "\e910";
  }
  .icon-Path-86337:before {
    content: "\e911";
  }
  .icon-Path-148536:before {
    content: "\e912";
  }
  .icon-pencil:before {
    content: "\e913";
  }
  .icon-Polygon-95:before {
    content: "\e914";
  }
  .icon-trash:before {
    content: "\e915";
  }
  .icon-upload:before {
    content: "\e916";
  }
  