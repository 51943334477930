@import "../../../styles/variables.module";
@import "../../../styles/fonts.module";

.imagePreviewModal {
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    :global(.ant-modal-content) {
        width: 70rem;

        :global(.ant-modal-body) {
            padding: 0.5rem 2.4rem;
        }
    }
    :global(.ant-modal-close) {
        top: 25px;
        right: 20px;
        i {
          font-size: 1rem;
        }
    }

    .imagePreviewModal__header {
        color: $black ;
        margin-bottom: 1rem;

        .modalBack {
            padding-right: 0rem;
            cursor: pointer;
        }

        .colorName {
            padding-left: 0rem;
            margin-left: 1rem;
            font: normal bold 1.8rem/2.6rem $primary-font;
            color: $black ;
        }
    }

    .imagePreviewModal__image_section {
        .noImageText {
            margin: auto;
            color: $white ;
            font: 600 1.8rem/2.6rem $secondary-font;
            text-align: center;
        }

        img {
            z-index: 5;
            max-width: 100%;
            height: auto;
            max-height: 500px;
        }

    }

    .imagePreviewModal__controllers {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: 85%;
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        font-size: 2rem;

        .imagePreviewModal__controllersPrev,
        .imagePreviewModal__controllersNext {
            background-color: $primary-button-color;
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2rem;
        }

        .disabledAction {
            background-color: $pagination-text-color;
            color: $white;
            cursor: not-allowed;
        }
    }
}
.darkTheme{
    :global(.ant-modal-content) {
    background-color: $dark-card-bg;
    border-color: $dark-card-border;
    }
    :global(.ant-modal-close) {
        i {
          font-size: 1rem;
          color: $white;
        }
    }

    .imagePreviewModal__header {
        color: $white ;

        .modalBack {
            color: $white ;
        }

        .colorName {
            color: $white ;
            font: normal bold 1.8rem/2.6rem $primary-font;
        }
    }
}
.invertTheme{
    :global(.ant-modal-content) {
        background-color: $black;
        border:0.1rem solid $white;
        }
        :global(.ant-modal-close) {
            i {
              font-size: 1rem;
              color: $white;
            }
        }
    
        .imagePreviewModal__header {
            color: $white ;
    
            .modalBack {
                color: $white ;
            }
    
            .colorName {
                color: $white ;
                font: normal bold 1.8rem/2.6rem $primary-font;
            }
        }
        .imagePreviewModal__controllers{
            .imagePreviewModal__controllersPrev,
            .imagePreviewModal__controllersNext {
                background-color: $white;
                &:hover{
                    background-color: $invert-color;
                }
                img{
                    color: $black;
                }
            }
            .disabledAction {
                background-color: $white;
                color: $black;
            }
        }
}