@import "/src/styles/_variables.module.scss";
@import "/src/styles/_fonts.module.scss";


label {
    font: normal normal normal 1.2rem/2rem $secondary-font;
}

.default {
    height: 4.2rem;
}

.ant-input:placeholder-shown {
    font: normal normal normal 1.5rem/2.3rem $secondary-font;
    color: $input-placeholder;
}

.ant-input:focus .ant-input:active {
    font: normal normal normal 1.5rem/2.3rem $secondary-font;
    color: $input-active;
    border: 0.1rem solid $input-focus;
    border-radius: 0.6rem;
}