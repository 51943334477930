@import "/src/styles/variables.module";
@import "/src/styles/fonts.module";

.wrapper{
  height: 100%;
  overflow-y: scroll;
  display: grid;
  grid-template-rows: 85% 15%;
  width: 100%;

  .container {
    width: 100%;
    overflow-x: scroll;
    :global(.ant-radio-group) {
      :global(p) {
        font: 300 1.6rem/2.4rem $secondary-font;
        color: $option-text-color;
      }
      :global(.ant-radio-checked) {
        :global(.ant-radio-inner) {
          background-color: $radio-button-inner-color;
          border-color: $radio-button-inner-color;
        }
      }
      :global(.ant-radio-wrapper) {
        &:hover {
          :global(.ant-radio-inner) {
            border-color: $radio-button-inner-color;
          }
        }
      }
    }
    :global(input) {
      font: 1.5rem/2.3rem $secondary-font;
      color: $skip-button-text;
    }
  }
  .logo {
    height: auto;
    width: 100%;
    max-width: 16.8rem;
    object-fit: contain;
    position: absolute;
    top: 10rem;
    left: 10rem;
  }
}
.darkTheme{
  :global(.ant-radio-group) {
    :global(.ant-radio-wrapper) {
    :global(p) {
      font: 300 1.6rem/2.4rem $secondary-font;
      color: $white;
    }
    :global(span) {
      font: 300 1.6rem/2.4rem $secondary-font;
      color: $white;
    }
  }
}
}
.invertTheme{
  :global(.ant-radio-group) {
    :global(.ant-radio-wrapper) {
      :global(.ant-radio-inner) {
        border-color: $black;
      }
      &:hover {
        :global(.ant-radio-inner) {
          border-color: $invert-red-color;
        }
      }
    }
    :global(.ant-radio-checked) {
      :global(.ant-radio-inner) {
        background-color: $invert-red-color;
        border-color: $invert-red-color;
      }
    }
  }
}
.logo {
  height: auto;
  width: 100%;
  max-width: 16.8rem;
  object-fit: contain;
  position: absolute;
  top: 10rem;
  left: 10rem;
}
