@import "/src/styles/variables.module";
@import "/src/styles/fonts.module";

.wrapper{
    .reviewContentWrapper{
      margin: 8rem 10rem 12rem 10rem;
      border: 0.1rem solid $white;
      background: $white 0% 0% no-repeat padding-box;
      box-shadow: 0rem 0.6rem 1.2rem $box-shadow-color;
      border: 0.1rem solid $card-border;
      border-radius: 1.6rem;
      opacity: 1;
      padding:6.7rem 3.3rem 12rem 6.8rem ;
      overflow-y: scroll;
      .reviewTitleBar{
        display: flex;
        flex-direction: column;
        .header{
          color: $dropdown-border-color;
          font: bold 2.6rem/3rem $primary-font;
        }
        .headerInfo{
          font: normal 1.25rem/2.6rem $secondary-font;
        }
      }
      .questionsContainer{
        padding: 2rem 0;
        .questionCard{
          &:last-child{
            border-bottom: none;
          }
          border-bottom: 1px solid $question-card-border;
          cursor: pointer;
          padding: 1rem 0;
          .questionTitle{
            font: normal 1.15rem/2.6rem $secondary-font;
          }
          .selectedOption{
            color: $dropdown-border-color;
            font: bold 1.15rem/2.6rem $secondary-font;
          }
        }
      }
    }  
    .active{
      background-color: $selection-yellow-color;
    }
    .darkTheme{
      background-color: $dark-card-bg;
      border-color: $dark-card-border;
      .reviewTitleBar{
        .header{
          color: $white;
          font: bold 2.6rem/3rem $primary-font;
        }
        .headerInfo{
          color: $white;
          font: normal 1.25rem/2.6rem $secondary-font;
        }

      }
      .questionsContainer{
        .questionCard{
          .questionTitle{
            color: $white;
            font: normal 1.15rem/2.6rem $secondary-font;
          }
          .selectedOption{
            color: $white;
            font: bold 1.15rem/2.6rem $secondary-font;
          }
        }
      }
    }
    .invertTheme{
      border-color:$black;
      .reviewTitleBar{
        .header{
          color: $black;
        }
        .headerInfo{
          color: $black;
        }

      }
      .questionsContainer{
        .questionCard{
          .questionTitle{
            color: $black;
          }
          .selectedOption{
            color: $black;
          }
          &:hover{
            background-color: $invert-red-color;
            padding: 1rem;
          }
        }
      }
    }
}
