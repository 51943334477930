@import "src/styles/_variables.module.scss";
@import "src/styles/_fonts.module.scss";



    .ant-btn {
        width: 100%;
        height: 3.6rem;
        border-radius: 0.8rem;
        border: none ;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font: normal normal 600 1.6rem/2.3rem $manrope-font;
        }
        i{
            font-size: 1.4rem;
            color: $white;
            margin-right: 0.7rem;
        }
        &:disabled{
            opacity: .5;
            cursor:not-allowed;
            font: normal normal 600 1.5rem/2.1rem $manrope-font;
        }
        i{
            margin-right: 0.7rem;
        }

    }
    .small{
        height: 3rem;
        > span{
            font: normal normal 600 1.4rem/2rem $primary-font;
        }
    }
    .medium{
        height: 4.2rem;
        background: $primary-button-color;
        border-radius: 0.8rem;
        > span{
            font: normal normal bold 1.6rem/2.4rem $primary-font;
        }
    }
    .primary {
        color: $primary-button-color;
        background-color: $primary-button-color ;
        border-radius: 0.8rem;
        &:hover{
            background: $button-hover 0% 0% no-repeat padding-box;
        }
        &:active{
            background: $button-active 0% 0% no-repeat padding-box;
        }
        span,i {
            color: $white;
        }
    }
    .previous {
        background-color: $secondary-button-color ;
        &:hover{
            background: $secondary-button-color 0% 0% no-repeat padding-box;
        }
        span {
            font: normal normal medium 1.4rem/2.4rem $primary-font;
            color: $white;
        }
    }

    .cancel {
        background-color: $cancel-button-color;
        &:hover{
            background: $cancel-button-hover-color 0% 0% no-repeat padding-box;
        }
        span {
            color: $secondary-text-color ;
        }
    }
    .text,.text_icon{
        background-color: transparent ;
        color: $primary-black;
        &_icon{
            i{
                color: $secondary-text-color;
                margin-right: 0;
            }
        }
        i{
            color: $secondary-text-color;
            font-size: 1.4rem;
        }
        &:hover{
            background: $cancel-button-color 0% 0% no-repeat padding-box;
        }
    }