@import "src/styles/_variables.module.scss";
@import "src/styles/_fonts.module.scss";

.accessibilityContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 2rem;
}

.logo {
  max-width: 22.6rem;
  height: auto;
  object-fit: contain;
  width: 100%;
}

.iconContainer {
  display: flex;
  justify-content: end;
  cursor: pointer;

  .accessbilityIconWrapper {
    background-color: $primary-button-color;
    border-radius: 50%;
    height: 5rem;
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6rem;

    i {
      font-size: 3rem;
      color: $white;
    }
  }
}

.popOverContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .button {
    :global(button) {
      width: 14.9rem;
      height: 4.2rem;

      :global(span) {
        font: 400 1.4rem/2.4rem $primary-font;
      }
    }

    :global(.ant-btn-default:not(:disabled)) {
      &:hover {
        border-color: $primary-button-color;
        color: $button-hover;
      }
    }
  }
}

.accessibilityIconContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0rem 2rem;
}

:global(.ant-popover) {
  position: relative;
  margin-left: 9%;

  :global(.ant-popover-content) {
    margin-left: 5%;

    :global(.ant-popover-inner-content) {
      padding: 0rem 1rem;
      height: 12rem;
      display: flex;
      align-items: center;

      .contentContainer {
        display: flex;
        align-items: center;

        .title {
          font: 500 3.2rem/4.6rem $primary-font;
          color: $dropdown-border-color;
        }

        .textSizeContainer {
          margin-left: 2rem;
          display: inline-flex;
          position: relative;

          .fontItemsWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;

            .selected {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              position: relative;
              width: 4rem;
              height: 4rem;
              border: 0.1rem solid $light-green;
              border-radius: 0.6rem;
              font: 500 1.4rem/2rem $primary-font;
              color: $light-green;
              text-transform: uppercase;
              cursor: pointer;

              .fontType {
                position: absolute;
                top: 0.1rem;
                right: 0.4rem;
                font-size: 0.9rem;
              }

              .item0 {
                font: 600 1.5rem/2.2rem $primary-font;
                color: $light-green;
              }

              .item1 {
                font: 500 1.9rem/2.7rem $primary-font;
                color: $light-green;
              }

              .item2 {
                font: bold 2.6rem/3.7rem $primary-font;
                color: $light-green;
              }
            }

            .fontItems {
              position: relative;
              font: 500 1.4rem/2rem $primary-font;
              color: $non-selected-item-color;
              cursor: pointer;
              opacity: 0.3;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 4rem;
              height: 4rem;
              border: 0.1rem solid $non-selected-item-bg;
              text-align: center;
              border-radius: 0.6rem;
              line-height: 20rem;

              .fontType {
                display: none;
              }

              .item0 {
                font: 600 1.5rem/2.2rem $primary-font;
                color: $primary-black;
              }

              .item1 {
                font: 500 1.9rem/2.7rem $primary-font;
                color: $primary-black;
              }

              .item2 {
                font: bold 2.6rem/3.7rem $primary-font;
                color: $primary-black;
              }
            }
          }

          .typeTitle {
            font: 1.6rem/2.3rem $primary-font;
            color: $dropdown-border-color;
            position: absolute;
            bottom: 0rem;
            top: 5rem;
            left: 6rem;
            text-align: center;
          }
        }

        .cursorContainer {
          margin-left: 4rem;

          span {
            font: 1.8rem/3.2rem $secondary-font;
            color: $skip-button-text;
          }

          :global(.ant-checkbox .ant-checkbox-inner) {
            width: 2rem;
            height: 2rem;
          }

          :global(.ant-checkbox-wrapper-checked) {
            :global(.ant-checkbox .ant-checkbox-inner) {
              width: 2rem;
              height: 2rem;
              background-color: $light-green;
              border-color: $light-green;
            }
          }
        }

        .readAloudContainer {
          margin-left: 1rem;

          .readAloudLabel {
            margin-right: 1rem;
            font: 1.8rem/3.2rem $secondary-font;
            color: $skip-button-text;
          }

          :global(.ant-switch) {
            background-color: $switch-off-bg;
            border-color: $switch-off-bg;
          }

          :global(.ant-switch-checked) {
            background-color: $light-green;
          }
        }

        .themeContainer {
          margin-left: 2rem;
          margin-top: 3rem;

          .themeItemsWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;

            .selected {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              position: relative;
              padding: 1rem 2rem;
              border: 0.1rem solid $light-green;
              border-radius: 0.6rem;
              font: 500 1.4rem/2rem $primary-font;
              color: $light-green;
              text-transform: uppercase;
              cursor: pointer;

              .themeType {
                position: absolute;
                top: 0.1rem;
                right: 0.4rem;
                font-size: 0.9rem;
              }
            }

            .themeItems {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              position: relative;
              padding: 1rem 2rem;
              border: 0.1rem solid $non-selected-item-bg;
              border-radius: 0.6rem;
              font: 500 1.4rem/2rem $primary-font;
              color: $non-selected-item-color;
              text-transform: uppercase;
              cursor: pointer;
              opacity: 0.3;

              .themeType {
                display: none;
              }
            }
          }

          .typeTitle {
            text-align: center;
            font: 1.6rem/2.3rem $primary-font;
            color: $dropdown-border-color;
            margin-top: 1rem;
          }
        }
      }

      .invertTheme {
        .title {
          font: 500 3.2rem/4.6rem $primary-font;
          color: $white;
        }
        background-color: $black;
        padding: 1rem;
        color: $white;

        :global(.ant-checkbox-wrapper) {
          :global(.ant-checkbox .ant-checkbox-inner) {
            background-color: $white;
            border-color: $white;
          }
        }
        .textSizeContainer {
          .fontItemsWrapper {
            .selected {
              .item0 {
                font: 600 1.5rem/2.2rem $primary-font;
                color: $light-green;
              }

              .item1 {
                font: 500 1.9rem/2.7rem $primary-font;
                color: $light-green;
              }

              .item2 {
                font: bold 2.6rem/3.7rem $primary-font;
                color: $light-green;
              }
            }

            .fontItems {
              border: 0.1rem solid $white;
              &:hover{
                background-color: $invert-color;
              }

              .item0 {
                font: 600 1.5rem/2.2rem $primary-font;
                color: $white;
              }

              .item1 {
                font: 500 1.9rem/2.7rem $primary-font;
                color: $white;
              }

              .item2 {
                font: bold 2.6rem/3.7rem $primary-font;
                color: $white;
              }
            }
          }

          .typeTitle {
            color: $white;
          }
        }

        .cursorContainer {
          span {
            color: $white;
          }
        }

        .readAloudContainer {
          .readAloudLabel {
            margin-right: 1rem;
            font: 1.8rem/3.2rem $secondary-font;
            color: $white;
          }
        }

        .themeContainer {
          .themeItemsWrapper {
            .themeItems {
              border: 0.1rem solid $white;
              color: $white;
              &:hover{
                background-color: $invert-color;
              }
            }
          }

          .typeTitle {
            color: $white;
          }
        }
      }

      .darkTheme {
        background-color: $dark-card-bg;
        padding: 1rem;
        color: $white;

        .title {
          font: 500 3.2rem/4.6rem $primary-font;
          color: $white;
        }

        .textSizeContainer {
          .fontItemsWrapper {
            .selected {
              .item0 {
                font: 600 1.5rem/2.2rem $primary-font;
                color: $light-green;
              }

              .item1 {
                font: 500 1.9rem/2.7rem $primary-font;
                color: $light-green;
              }

              .item2 {
                font: bold 2.6rem/3.7rem $primary-font;
                color: $light-green;
              }
            }

            .fontItems {
              border: 0.1rem solid $white;

              .item0 {
                font: 600 1.5rem/2.2rem $primary-font;
                color: $white;
              }

              .item1 {
                font: 500 1.9rem/2.7rem $primary-font;
                color: $white;
              }

              .item2 {
                font: bold 2.6rem/3.7rem $primary-font;
                color: $white;
              }
            }
          }

          .typeTitle {
            color: $white;
          }
        }

        .cursorContainer {
          span {
            color: $white;
          }
        }

        .readAloudContainer {
          .readAloudLabel {
            margin-right: 1rem;
            font: 1.8rem/3.2rem $secondary-font;
            color: $white;
          }
        }

        .themeContainer {
          .themeItemsWrapper {
            .themeItems {
              border: 0.1rem solid $white;
              color: $white;
            }
          }

          .typeTitle {
            color: $white;
          }
        }
      }
    }
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  align-items: center;
  z-index: 1000;
  width: 100%;
  padding-top: 2rem;
  background-color: $body-background-color;
}


.invertThemeheaderContainer{
  .iconContainer{
    .accessbilityIconWrapper {
      background-color: $black;
      &:hover{
        background-color: $invert-red-color;
      }
    }
  }
  .button{
    :global(button) {
      background-color: $black;
      &:hover{
        background-color: $invert-red-color;
      }
    }
  }
  .popOverContainer{
    padding-right: 2rem;
  }
}
.darkThemeheaderContainer{
  background-color:$black;
}
.darkThemeContainer{
  :global(.ant-popover-content){
  :global(.ant-popover-inner){
    background-color: $dark-card-bg;
    border:0.1rem solid $white;
  }
}
}
.invertThemeContainer{
  :global(.ant-popover-content){
  :global(.ant-popover-inner){
    background-color: $black;
    border:0.1rem solid $white;
  }
}
}
