.offline {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    inset: 0;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 9999;

    &__text {
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        color: rgba(0, 0, 0);
        font-size: 28px;
        padding: 20px 30px;
    }
}