@import "../../../styles/variables.module";
@import "../../../styles/fonts.module";

.setTypeContainer {
  padding: 5rem 8rem;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;

  .content {
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    flex-flow: row nowrap;
  }

  .logo {
    max-width: 25.6rem;
    height: auto;
    object-fit: contain;
    width: 100%;
    margin-left: -1.2rem;
  }

  .setTypeLogoText {
    text-align: left;
    font: 400 2.8rem $secondary-font;
  }

  .setTypeCard {
    background-color: $card-background;
    border-radius: 1.6rem;
    opacity: 1;
    box-shadow: $card-box-shadow;
    border: 0.1rem solid $card-border;

    .setTypeContainer {
      padding: 2rem 0;

      .setTypeTitle {
        font: normal 1.75rem/2.5rem $primary-font;
        color: $survey-set-label-color;
        padding-bottom: 1rem;
      }

      .setTypeCardWrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 2rem;

        .setTypeCard {
          padding: 3rem 1rem;
          border: 0.1rem solid $report-config-bg;
          width: 45%;
          color: $teritary-text;
          font: normal 2rem/3rem $secondary-font;
          border-radius: 0.8rem;
          cursor: pointer;

          .textContainer {
            display: flex;
            justify-content: center;
            padding: 0rem 2rem 1rem 1rem;
            text-transform: capitalize;
            text-align: center;

            .setTypeCardText {
              font: 2.4rem/2.4rem $secondary-font;
              color: $option-text-color;
            }
          }

          i {
            visibility: hidden;
            font-size: 1.25rem;
          }
        }

        .selected {
          i {
            color: $light-green;
            visibility: visible;
            position: relative;
            left: 90%;
            top: -3rem;
          }

          color: $option-text-color;
          border: 0.1rem solid $light-green;
        }
      }
    }
  }

  .darkTheme {
    background-color: $dark-card-bg;
    border: 0.1rem solid $dark-card-border;
    color: $white;
    border-radius: 1.6rem;

    .setTypeText {
      color: $white;
    }

    .submitButton {
      border-color: $primary-button-color;
    }

    .setTypeContainer {
      .setTypeCardWrapper {
        .setTypeCard {
          border-radius: 0.6rem;
          opacity: 1;
          box-shadow: $card-box-shadow;
          background-color: $dark-option-card-bg;
          border: 0.1rem solid $dark-option-card-border;

          .textContainer {
            display: flex;
            justify-content: center;

            .setTypeCardText {
              color: $white;
              text-align: center;
            }
          }
        }

        .selected {
          color: $option-text-color;
          border: 0.1rem solid $light-green;

        }

      }

    }
  }

  .invertTheme {
    border: 0.1rem solid $black;

    .setTypeText {
      color: $black;
    }

    .setTypeContainer {
      .setTypeCardWrapper {
        .setTypeCard {
          border: 0.1rem solid $black;

          &:hover {
            border-color: $invert-red-color;
          }
        }

        .selected {
          border: 0.1rem solid $invert-red-color;

          i {
            color: $invert-red-color;
          }
        }
      }
    }

    .submitButton {
      background-color: $black;

      &:hover {
        background-color: $invert-red-color;
      }
    }
  }

  .cardContainer {
    padding: 2rem;
  }

  .setTypeText {
    font: 500 2.6rem/3.4rem $primary-font;
    color: $dropdown-border-color;
    padding: 0rem 2rem;
  }


  .submitButton {
    width: 100%;
    height: 4rem;
  }

  .buttonContainer {
    margin-top: 1rem;
    padding: 0rem 2rem;

    :global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover) {
      &:hover {
        border-color: $primary-button-color ;
      }
    }
  }
}


@media (min-width: 1200px) {

  /* Adjust styles for screens up to 1200px width */
  .setTypeContainer {
    padding: 5rem 8rem;
  }

  .logo {
    max-width: 22rem;
  }

}

@media (min-width: 1920px) {

  /* Adjust styles for screens up to 1200px width */
  .setTypeContainer {
    padding: 28rem 8rem 16rem 10rem;
  }

  .logo {
    max-width: 26rem;
  }
}

@media screen and (max-width: 768px) {

  /* Adjust styles for screens up to 768px width */
  .setTypeContainer {
    padding: 4rem 2rem;

    .logo {
      max-width: 15rem;
      margin-left: -0.7rem;
    }

    .setTypeLogoText {
      font: 400 2rem $secondary-font;
    }

    .setTypeCard {
      .cardContainer {
        padding: 0rem;
      }

      .setTypeText {
        font: 500 2rem/2.5rem $primary-font;
        margin-bottom: 0;
      }

      .setTypeContainer {
        .setTypeCardWrapper {
          .setTypeCard {
            padding: 2rem 0.5rem;

            .textContainer {
              padding: 0rem 0 1rem;

              .setTypeCardText {
                font: 2rem/2.4rem $secondary-font;
              }
            }
          }
        }
      }
    }

    .submitButton,
    .skipButton {
      font-size: 1.2rem;
      height: 3rem;
    }
  }
}