/* Color palette */
$light-white: #FFFFFF40;
$primary-light-color: #0D494E10;
$primary-black: #4B4B4B;
$body-bg: #F5F8FA;
$table-bg: #F7FAFC;
$input-bg: #F8FBFC;
$white:#FFFFFF;
$black:#000000;
$danger-color: #FE0000;

$primary-button-color: #F15B5C;
$main-text-color: #003A58;
$main-text-color-brown: #4A4A4A;
$secondary-text-color: #6E768A;
$teritary-text: #A4A8C3;
$light-green: #58BAB9;
$light-red: #F15B5C;
$light-purple: #6D5DC4;
$button-hover:#D53B3C;
$button-active:#F57F80;
$secondary-button-color:#C3C3C3;
$input-placeholder:#BFC0CD;
$input-active:#2F3444;
$card-border:#EAEFF2;
$skip-button-text:#1B3D6F;
$footer-background:#003A58;
$previous-button-bg: #58BAB900;
$box-shadow-color:#2139580A;
$cancel-button-color:#ECEDF0;
$cancel-button-hover-color: #E1E3E7;
$input-focus:#EAEFF2;
$card-background:#feffff;
$footer-background:#003A58;
$previous-button-bg: #58BAB900;
$box-shadow-color:#2139580A;
$success-card-bg:#D5F8D7;
$success-card-box-shadow:#21395808;
$cancel-button-color:#ECEDF0;
$cancel-button-hover-color: #E1E3E7;
$error-color:#D03939;
$dropdown-border-color:#103760;
$body-background-color:#FBFBF6;
$cancel-button-color:#ECEDF0;
$cancel-button-hover-color: #E1E3E7;
$card-background:#feffff;
$instructions-text-color:#4E4E4E;
$option-text-color:#2B2B2B;
$radio-button-inner-color:#00C070;
$report-container-bg:#F4F6F8;
$question-card-border: #EDF1F4;
$report-config-bg:#F6FAFB;
$survey-set-label-color: #101926;
$slection-code-color:#C3A13B;
$non-selected-item-color:#4F4F4F;
$non-selected-item-bg:#4E4E4E4D;
$switch-off-bg:#737473;
$pagination-text-color:#6A7986;
$dark-card-bg:#2C2C2C;
$dark-card-border:#474748;
$dark-option-card-bg:#3B3B3B;
$dark-option-card-border:#4D4D4D;
$invert-red-color:#FF0000;
$invert-color:#18EBFF;
$selection-yellow-color:#FFFF00;

/* Spacer variables */
$spacer-x : 1rem;
$spacer-y : 1rem;

$card-box-shadow: 0rem 0.6rem 1.2rem #2139580a;