@import "/src/styles/variables.module";
@import "/src/styles/fonts.module";


.invertTheme{
    filter: invert(100%);
    :global(.ant-radio-inner){
        border-color: $light-green;
    }
    canvas,
    img{
        filter: invert(100%);
    }
}
.darkTheme{
    background-color: $black;
    color: $white;
}

.mainWrapper{
    display: grid;
    grid-template-rows: 20% 80%;
    height: 100vh;
    width: 100%;
    overflow-x: scroll;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
      
}

.bigCursor{
    cursor: url("../../../assets/images/cursor.svg"), auto;
}