@import "../../../styles/variables.module";
@import "../../../styles/fonts.module";

.languageContainer {
  padding: 5rem 8rem;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;

  .content {
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    flex-flow: row nowrap;
  }

  .logo {
    max-width: 25.6rem;
    height: auto;
    object-fit: contain;
    width: 100%;
    margin-left: -1.2rem;
  }

  .languageLogoText {
    text-align: left;
    font: 400 2.8rem $secondary-font;
  }

  .languageCard {
    background-color: $card-background;
    border-radius: 1.6rem;
    opacity: 1;
    box-shadow: $card-box-shadow;
    border: 0.1rem solid $card-border;

    .languageContainer {
      padding: 2rem;

      .languageTitle {
        font: normal 1.75rem/2.5rem $primary-font;
        color: $survey-set-label-color;
        padding-bottom: 1rem;
      }

      .languageCardWrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 2rem;

        .languageCard {
          padding: 3rem 1rem;
          border: 0.1rem solid $report-config-bg;
          color: $teritary-text;
          font: normal 2rem/3rem $secondary-font;
          border-radius: 0.8rem;
          cursor: pointer;

          .textContainer {
            padding: 0rem 2rem 1rem 2rem;
            text-transform: capitalize;

            .languageCodeText {
              font: 600 1.4rem/2.4rem $secondary-font;
              color: $slection-code-color;
              margin-top: 0.5rem;
            }

            .languageCardText {
              font: 2.4rem/2.4rem $secondary-font;
              color: $option-text-color;
            }
          }
        }

        i {
          visibility: hidden;
          font-size: 1.25rem;
        }

        .selected {
          i {
            color: $light-green;
            visibility: visible;
            position: relative;
            left: 90%;
            top: -3rem;
          }

          color: $option-text-color;
          border: 0.1rem solid $light-green;
        }
      }
    }
  }
  .darkTheme {
    background-color: $dark-card-bg;
    border: 0.1rem solid $dark-card-border;
    color: $white;
    border-radius: 1.6rem;
  
    .cardContainer {
      .languageText {
        color: $white;
      }
  
      .submitButton {
        border-color: $primary-button-color;
      }
    }
  
    .languageContainer {
      .languageCardWrapper {
        .languageCard {
          border-radius: 0.6rem;
          opacity: 1;
          box-shadow: $card-box-shadow;
          background-color: $dark-option-card-bg;
          border: 0.1rem solid $dark-option-card-border;
  
          .textContainer {
            .languageCardText {
              color: $white;
            }
          }
        }
        .selected {
          color: $option-text-color;
          border: 0.1rem solid $light-green;
  
        }
  
      }
  
    }
  }
  
}

.invertThemeContainer {
  .invertTheme {
    border-color: $black;

    .languageText {
      color: $black;
    }

    .languageContainer {
      .languageCardWrapper {
        .languageCard {
          border: 0.1rem solid $black;

          &:hover {
            border-color: $invert-red-color ;
          }

          .textContainer {
            .languageCodeText {
              color: $black;
            }

          }
        }

        .selected {
          border: 0.1rem solid $invert-red-color;

          i {
            color: $invert-red-color;
          }

        }
      }
    }

    .submitButton {
      background-color: $black;

      &:hover {
        background-color: $invert-red-color;
      }
    }
  }

}


.cardContainer {
  padding: 2rem;
}

.languageText {
  font: 500 2.6rem/3.4rem $primary-font;
  color: $dropdown-border-color;
  padding: 0rem 2rem;
}


.submitButton {
  width: 100%;
  height: 4rem;
}

.buttonContainer {
  margin-top: 1rem;
  padding: 0rem 2rem;

  :global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover) {
    &:hover {
      border-color: $primary-button-color ;
    }
  }
}



@media (min-width: 1200px) {

  /* Adjust styles for screens up to 1200px width */
  .landingEmail {
    padding: 18rem 8rem 18rem 10rem;
  }

  .logo {
    max-width: 22rem;
  }

}

@media (min-width: 1920px) {

  /* Adjust styles for screens up to 1200px width */
  .landingEmail {
    padding: 28rem 8rem 16rem 10rem;
  }

  .logo {
    max-width: 26rem;
  }
}

@media screen and (max-width: 768px) {

  /* Adjust styles for screens up to 768px width */
  .languageContainer {
    padding: 4rem 2rem;

    .logo {
      max-width: 15rem;
      margin-left: -0.7rem;
    }

    .languageLogoText {
      font: 400 2rem $secondary-font;
    }

    .languageCard {
      .cardContainer {
        padding: 0;
      }

      .languageText {
        font: 500 2rem/3.4rem $primary-font;
        padding: 0rem 1rem;
      }

      .languageContainer {
        padding: 2rem;

        .languageTitle {
          font: normal 2rem/2.5rem $primary-font;
        }

        .languageCardWrapper {
          .languageCard {
            padding: 2rem 0.75rem;
            font: normal 2rem/3rem $secondary-font;

            .textContainer {
              padding: 0rem 1rem 0.5rem 1rem;

              .languageCodeText {
                font: 600 1.2rem/2.4rem $secondary-font;
                margin-top: 0.5rem;
              }

              .languageCardText {
                font: 1.8rem/2.4rem $secondary-font;
              }
            }

            i {
              font-size: 1.2rem;
            }
          }

          .selected {
            i {
              left: 85%;
              top: -2.5rem;
            }
          }
        }
      }
    }
  }

  .emailText {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .submitButton,
  .skipButton {
    font-size: 1.2rem;
    height: 3.5rem;
  }

  .emailCard {
    margin-top: 5rem;
  }
}