@import '../../../styles/variables.module';
@import '../../../styles/fonts.module';


.questionCard{
    margin: 8rem 10rem 15%;
    border: 0.1rem solid $white;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.6rem 1.2rem $box-shadow-color;
    border: 0.1rem solid $card-border;
    border-radius: 1.6rem;
    opacity: 1;
    padding:6.7rem 3.3rem 6.7rem 6.8rem ;
    overflow-y: scroll;
    width: calc(100% - 20rem);
    overflow-x: scroll;
    :global(.ant-select) {
       width: 35.5rem;
       height: 4.5rem;
      }
    :global(.ant-select-show-search){
          font: 1.5rem/2.3rem $secondary-font;
          color: $skip-button-text;
          margin-bottom: 2rem;
    }
    :global(.ant-select:where(.css-dev-only-do-not-override-1g853jt):not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector){
      border-color:$dropdown-border-color;
    }
    :global(.ant-select-focused:where(.css-dev-only-do-not-override-1g853jt).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector){
      border-color:$dropdown-border-color;
    }
    .attachmentContainer{
      .viewMoreButton{
        display: flex;
        align-items: center;
      }
    }
    .checkboxContainer{
      display: flex;
      flex-direction: column;
      gap: 10px;
      p{
        font: 2rem/3.2rem $secondary-font;
        margin:0;
      }
      :global(.ant-checkbox-wrapper){
        &:hover {
          :global(.ant-checkbox-inner){
            border-color:$primary-button-color;
          }
          :global(.ant-checkbox-checked .ant-checkbox-inner){
            background-color: $primary-button-color;
            border-color:$primary-button-color;
          }
        }
      }
      :global(.ant-checkbox-input){
        cursor: default;
      }

      :global(.ant-checkbox-checked .ant-checkbox-inner) {
        background-color: $primary-button-color;
        border-color:$primary-button-color;
      }
    }
    :global(.ant-radio-group){
      :global(.ant-radio-wrapper){
        span{
          font: 2rem/3.2rem $secondary-font;
        }
      }
    }
}
.darkTheme{
  background-color: $dark-card-bg;
  border-color: $dark-card-border;
  color: $white;
  :global(.ant-checkbox-wrapper){
      span{
        p{
          color: $white;
        }
      }
  }
}
.invertTheme{
  border-color: $black;
}

.question{
    font: 300 2.4rem/3.8rem $secondary-font;
}

.radioOption{
    display: flex;
}

.error{
    margin-top:2rem;
    font: 1.4rem/1.3rem $manrope-font;
    color: $error-color;
}
.dropDrown{
border: 0.1rem solid $dropdown-border-color;
border-radius: 0.6rem;
opacity: 1;
height: 20rem;
}