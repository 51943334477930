@import "/src/styles/_variables.module.scss";
@import "/src/styles/_fonts.module.scss";

    .loading__container {
        margin: 2rem 0rem;
        margin-bottom: 20rem;
        padding: 5rem;
        text-align: center;
      }
      
