@import "../../../styles/variables.module";
@import "../../../styles/fonts.module";

.landingEmail {
  padding: 6rem 8rem;
  height: 100%;
  overflow-y: scroll;

  .content{
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    flex-flow: row nowrap;
  }
}

.logo {
  max-width: 25.6rem;
  height: auto;
  object-fit: contain;
  width: 100%;
  margin-left: -1.2rem;
}

.landingEmailLogoText {
  text-align: left;
  font: 400 2.8rem $secondary-font;
}

.emailCard {
  background: $card-background 0% 0% no-repeat padding-box;
  border-radius: 1.6rem;
  opacity: 1;
  box-shadow: $card-box-shadow;
  border: 0.1rem solid $card-border;    
}
.darkTheme{
  background-color: $dark-card-bg;
  border: 0.1rem solid $dark-card-border;
  color: $white;
  border-radius: 1.6rem;
  .cardContainer{
    .emailText{
      color: $white;
    }
    .submitButton{
      border-color: $primary-button-color;
    }
  }
}
.invertTheme{
  border: 0.1rem solid $black;
  .cardContainer{

  :global(input){
    border-color: $black;
  }
  :global(.ant-input:hover){
    border-color: $black;
  }
  .submitButton{
   background-color: $black;
    &:hover{
      background-color: $invert-red-color;
    }
  }
  .emailText{
    color: $black;
  }
}
.skipButton{
  color: $black;
}
.skipButtonContainer{
  :global(.ant-btn-default:not(:disabled)){
    &:hover{
      color: $invert-red-color;
    }
  }
}

}

.cardContainer {
  padding: 2rem;
  :global(span){
    font: 400 1.4rem/2.4rem $primary-font;
  }
  :global(.ant-input){
    font: 1.5rem/2.3rem $secondary-font;
    color: $skip-button-text;
    &:hover,
    &:focus{
      border-color: $dropdown-border-color;
    }
  }
  :global(.input__error){
    font:  1.4rem/1.3rem $manrope-font;
    margin-top:1rem
  }
}

.emailText {
  font: 500 2.6rem/3.4rem $primary-font;
  color: $dropdown-border-color;
  margin-bottom: 3rem;
}


.submitButton {
  width: 100%;
  height: 4rem;
}

.skipButton {
  text-align: center;
  font-size: 1.4rem;
  font-family: $secondary-font;
  color: $skip-button-text;
  cursor: pointer;
  border: none;
}
.skipButtonContainer{
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  :global(.ant-btn-default:not(:disabled)){
    &:hover{
      color: $skip-button-text;
     }
  }
}

.buttonContainer {
  margin-top: 4.6rem;
  .submitButtonContainer{
    :global(.ant-btn-default:not(:disabled)) {
      &:hover {
        background-color: $button-hover;
        border-color: $primary-button-color;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  /* Adjust styles for screens up to 768px width */
  .landingEmail {
    padding: 4rem 2rem;
  }

  .landingEmailLogoText {
    font-size: 1.9rem;
  }

  .logo {
    max-width: 15rem;
    margin-left: -0.7rem;
  }

  .emailText {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }

  .cardContainer {
    padding: 1.5rem;

    .ant-input {
      font-size: 0.8rem;
    }

    .input__error {
      font-size: 0.8rem;
      margin-top: 0.5rem;
    }
  }

  .submitButton,
  .skipButton {
    font-size: 1rem;
    height: 3.5rem;
  }
  .emailCard{
    margin-top: 5rem;
  }
}