@import "/src/styles/variables.module";

.card {
  background: $card-background 0% 0% no-repeat padding-box;
  border: 0.1rem solid $card-border;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: $card-box-shadow;
  border: 0.1rem solid $card-border;
  border-radius: 1.6rem;
  opacity: 1;
  padding: 5rem;
}
.button {
  width: 38rem;
  height: 4.2rem;
  margin-top: 1rem;
}
.darkTheme{
  :global(.ant-card-bordered){
    background-color: $dark-card-bg;
    border-color: $dark-card-border;
    color: $white;
  }
  :global(.ant-card-body){
    p{
      color: $white;
    }
  }
  .button{
    border-color: $primary-button-color;
  }
}
.invertTheme{
  border: 0.1rem solid $black;
  .button {
    background-color: $black;
    &:hover{
      background-color: $invert-red-color;
    }
  }
}
