
@import "../../../styles/variables.module";

.document {
  :global(.react-pdf__Page) {
    background-color: $white !important;
    margin:0rem 5rem 5rem 5rem;
  }
}
.loading {
  margin: auto;
  width: 100%;
  height: auto;
}
.container{
  width: 80%;
  .invertTheme{
    :global(.react-pdf__Page){
      :global(.react-pdf__Page__canvas) {
        border:0.1rem solid $black ;
      }
    } 
  }
}
